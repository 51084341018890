<template>
  <div class="member_center">
    <el-tabs type="card" v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="會員資料" name="Shared_Member"></el-tab-pane>
      <el-tab-pane label="付款資訊" name="Shared_Order"></el-tab-pane>
    </el-tabs>
  </div>
  <router-view />
</template>

<script>
export default {
  name: "MemberCenter",
  data() {
    return {
      activeName: this.$route.name,
    };
  },
  methods: {
    handleClick(tab) {
      this.$router.push(`/MemberCenter/${this.removeUnderscorePrefix(tab.props.name)}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.member_center {
  padding: 1rem 1.5rem 0;
}
</style>
